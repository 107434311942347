.boardlist {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    height: auto;
  }

.boardlist-label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 0.5px;
    text-decoration: underline;
    margin-top: 20px;
    margin-bottom: -10px;
}

.board-list-info {
  margin: 0px 40px;
  font-size: 14px;
}

.delete-rules {
  font-style: italic;
}