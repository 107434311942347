*{
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background-image: url('/src/SideBeef.png');
  background-attachment: fixed;
  background-size: cover;
  color: white;
}

.forms {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

/* FORM INPUT AND LABEL STYLING */
.input-fields {
  text-align: left;
  margin: 10px;
}

label {
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
}

input {
  width: 100%;
}

/* GENERAL BUTTON STYLING */
button {
  outline: none;
  border: none;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  padding: 8px;
  font-size: 11px;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
  box-shadow: 2px 2px #ccc;
  transition: background-color 0.5s;
}

button:hover, button:active {
  background-color: #555;
}

button:active {
  box-shadow: 0 0px #ccc;
  transform: translate(2px, 2px);
}

html{
  cursor:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='36' height='43' viewport='0 0 100 100' style='fill:black;font-size:22px;'><text y='50%'>🥩</text></svg>") 16 0,auto; /*!emojicursor.app*/
}