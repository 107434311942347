.card {
    white-space: normal;
    border-radius: 5%;
    border: 1px solid #777;
    box-shadow: 2px 2px 4px #666;
    background-color: #fff;
    color: #000;
    height: 250px;
    width: 250px;
    min-width: 250px;
    margin: 20px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.id {
  font-size: 28px;
  font-style: italic;
  text-align: left;
  color: #ccc;
  padding-bottom: 10px;
  letter-spacing: 2px;
}
  
.message {
    font-family: Arial;
    font-size: 14px;
    line-height: 21px;
}
  
.controls {
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
/* DELETE BUTTON STYLING */
.delete-btn {
    background-color: #C62828;
    margin: 0px;
}

/* UPVOTE BUTTON STYLING */
.upvote-btn {
  background-color: #eee;
  color: #C62828;
  margin: 0px;
}

.upvote-btn:hover, .upvote-btn:active {
  color: #fff;
}