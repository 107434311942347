.board {
    border-radius: 10px;
    border: 1px solid #666;
    box-shadow: 4px 4px #555;
    background-color: #C62828;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    margin: 20px;
    padding: 12px;
  }

.board:active, .board:hover, .active {
    background-color: #666;
}

.board:active, .active {
    box-shadow: 0 0px #555;
    transform: translate(4px, 4px);
  }