/* VERSION A (horizontal scroll) */
.cardlist {
    display: flex;
    align-items: center;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 300px;
    overflow: auto;
    width: 100%;
    white-space: nowrap;
  }

/* VERSION B (wrap)
.cardlist {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  } */