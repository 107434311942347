.new-card-form {
  width: 490px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-message {
    border: 2px solid red;
    padding: 8px;
    color: red;
  }