.new-board-form {
    width: 490px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-content {
    border-radius: 10px;
    border: 1px solid #bbb;
    box-shadow: 2px 2px 4px #aaa;
    background-color: #fff;
    color: #000;
    height: auto;
    width: 400px;
    margin: 20px;
    padding: 25px;
}

.form-content--hidden {
    display: none;
}

/* TOGGLE BUTTON STYLING */
.toggle-btn {
    background-color: #c67728;
    font-size: 16px;
    width: 300px;
    box-shadow: 4px 4px #555;
}

toggle-btn:active {
    transform: translate(4px, 4px);
}

/* SUBMIT BUTTON STYLING */
.submit-btn {
    background-color: #28c628;
}

.error-message {
    border: 2px solid red;
    padding: 8px;
    color: red;
}